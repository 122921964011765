import * as React from "react"
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Link } from "gatsby"
import { ArrowRight, ArrowDown } from 'react-feather';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


const NewLayoutThree = () => {
  const data = useStaticQuery(graphql`
  query {
    coverBuoy: file(relativePath: { eq: "buoy-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverBootstrap: file(relativePath: { eq: "bootstrap-design-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverCandor: file(relativePath: { eq: "candor-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverMarksHub: file(relativePath: { eq: "markshub-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverEcho: file(relativePath: { eq: "echo-ship.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverPointOfCare: file(relativePath: { eq: "point-of-care.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverFamilyApp: file(relativePath: { eq: "family-app.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coverCalendarCentral: file(relativePath: { eq: "calendar-central.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }


  }
`)
return (
    <Grid>
    <Navbar />
    <div className="Header">
      <h1 className="Title">Hi, I'm Nate</h1>
      <p className="SubTitle">
        Product Designer & Coder based in the Midwest.
      </p>
      <div className="ButtonWrapper">
        <Button href="mailto:orpheusdevelopment@gmail.com">
          Let's Talk
          <ArrowRight />
        </Button>
        <ButtonOutline href="#mywork">
          My Work
          <ArrowDown />
        </ButtonOutline>
      </div>
    </div>

    {/* Bootstrap Design */}
    <Clickable to="/projects/BootstrapDesign">
      <CoverGridRight id="mywork">
      <CoverImage fluid={data.coverBootstrap.childImageSharp.fluid} />
        <div className="CoverText">
          <p className="ProjectType">Figma Design System & Landing Page</p>
          <h2 className="ProjectTitle">Bootstrap Design</h2>
          <ProjectButton to="/projects/BootstrapDesign">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
      </CoverGridRight>
    </Clickable>

    {/* Candor */}
    <Clickable to="/projects/Candor">
      <CoverGridLeft>
        <div className="CoverText">
          <p className="ProjectType">Motion Design & Prototyping</p>
          <h2 className="ProjectTitle">Candor</h2>
          <ProjectButton to="/projects/Candor">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
        <CoverImage fluid={data.coverCandor.childImageSharp.fluid} />
      </CoverGridLeft>
    </Clickable>

    {/* MarksHub - Underwriters Labratory */}
    <Clickable to="/projects/MarksHub">
      <CoverGridRight>
        <CoverImage fluid={data.coverMarksHub.childImageSharp.fluid} />
        <div className="CoverText">
          <p className="ProjectType">Redesign & Front End Dev</p>
          <h2 className="ProjectTitle">Underwriters Labratory</h2>
          <ProjectButton to="/projects/MarksHub">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
      </CoverGridRight>
    </Clickable>

    {/* Echo Ship */}
    <Clickable to="/projects/EchoLogistics">
      <CoverGridLeft>
        <div className="CoverText">
          <p className="ProjectType">Sales Prototype & Pattern Library</p>
          <h2 className="ProjectTitle">Echo Ship</h2>
          <ProjectButton to="/projects/EchoLogistics">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
        <CoverImage fluid={data.coverEcho.childImageSharp.fluid} />
      </CoverGridLeft>
    </Clickable>

    {/*Point of Care*/}
    <Clickable to="/projects/PointOfCare">
      <CoverGridRight>
        <CoverImage fluid={data.coverPointOfCare.childImageSharp.fluid} />
        <div className="CoverText">
          <p className="ProjectType">Product Design & User Testing</p>
          <h2 className="ProjectTitle">Point of Care</h2>
          <ProjectButton to="/projects/PointOfCare">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
      </CoverGridRight>
    </Clickable>

    {/* Caremerge Family App */}
    <Clickable to="/projects/FamilyApp">
      <CoverGridLeft>
        <div className="CoverText">
          <p className="ProjectType">Product Design</p>
          <h2 className="ProjectTitle">Caremerge Family App</h2>
          <ProjectButton to="/projects/FamilyApp">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
        <CoverImage fluid={data.coverFamilyApp.childImageSharp.fluid} />
      </CoverGridLeft>
    </Clickable>

    {/*Calendar Central*/}
    <Clickable to="/projects/CalendarCentral">
      <CoverGridRight>
        <CoverImage fluid={data.coverCalendarCentral.childImageSharp.fluid} />
        <div className="CoverText">
          <p className="ProjectType">Product Design & User Testing</p>
          <h2 className="ProjectTitle">Calendar Central</h2>
          <ProjectButton to="/projects/CalendarCentral">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
      </CoverGridRight>
    </Clickable>

    {/*  Buoy */}
    <Clickable to="/projects/Buoy">
      <CoverGridLeft>
        <div className="CoverText">
          <p className="ProjectType">App + Landing Page Design & Dev</p>
          <h2 className="ProjectTitle">Buoy</h2>
          <ProjectButton to="/projects/Buoy">
            Learn More
            <ArrowRight />
          </ProjectButton>
        </div>
        <CoverImage fluid={data.coverBuoy.childImageSharp.fluid} />
      </CoverGridLeft>
    </Clickable>



    {/* <SiteFooter></SiteFooter> */}
    <Footer />
    </Grid>
  )
}

export default NewLayoutThree

const CoverGridRight = styled.div`

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8%;
  margin-bottom: 6%;
  animation: fadeIn cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  opacity: 0;
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1.5rem;
  padding: 4%;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
  opacity: 0.9;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  .CoverText {
    align-self: center;
    display: grid;
    @media (max-width: 750px) {
      display: grid;
    }
  }

  .ProjectType {
    font-weight: 500;
    color: var(--black-blue);
    margin-bottom: 32px;
    font-size: clamp( 16px, 2vw, 24px);
    @media (max-width: 750px) {
      margin-bottom: 16px;
      order: 2;
    }
  }
  .ProjectTitle {
    color: var(--black-blue);
    margin-bottom: 32px;
    font-size: clamp(100%, 28px + 2vw, 48px);
    @media (max-width: 750px) {
      margin-bottom: 8px;
      order: 1;
    }
  }
`

const CoverImage = styled(Img)`
  max-width: 100%;
  border-radius: 16px;
  box-shadow: 0 .4rem 1.6rem rgba(9,9,11,0.04), 0 .1rem .3rem rgba(9,9,11,0.08);
  /* box-shadow: 0 80px 120px -40px rgb(0 0 0 / 10%);
  transition: .3s ease; */
  @media (max-width: 800px) {
      /* order: 1; */
  }
  /* :hover {
    transform: scale3d(1.02,1.02,1.02);
  } */
`


const CoverGridLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8%;
  margin-bottom: 6%;
  animation: fadeIn cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  opacity: 0;
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1.5rem;
  padding: 4%;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
  opacity: 0.9;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  }


  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  .CoverText {
    align-self: center;
    display: grid;
    @media (max-width: 750px) {
      display: grid;
      order: 2;
    }
  }

  .ProjectType {
    font-weight: 500;
    color: var(--black-blue);
    margin-bottom: 32px;
    font-size: clamp( 16px, 2vw, 24px);
    @media (max-width: 750px) {
      margin-bottom: 16px;
      order: 2;
    }
  }
  .ProjectTitle {
    color: var(--black-blue);
    margin-bottom: 32px;
    font-size: clamp(100%, 28px + 2vw, 48px);
    @media (max-width: 750px) {
      margin-bottom: 8px;
      order: 1;
    }
  }
`


// const CoverGridLeft = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-column-gap: 72px;
//   margin-bottom: 112px;

//   @media (max-width: 800px) {
//     grid-template-columns: 1fr;
//     grid-row-gap: 32px;
//     .text-area {
//       order: 2;
//     }

//   }

//   div {
//       @media (max-width: 900px) {
//         margin: 0 auto;
//       }
//   }

//   .text {
//       font-weight: 500;
//       color: #222F3E;
//       margin-bottom: 32px;
//       font-size: clamp(100%, 16px + 1vw, 24px);
//     }
//   .lead {
//     color: #222F3E;
//     margin-bottom: 32px;
//     font-size: clamp(100%, 28px + 2vw, 48px);
//     /* font-size: clamp(100%, 1rem + 2vw, 48px); */
//   }
//   .text-area {
//     align-self: center;
//   }
// `

const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 32px;
  //Page Loading Animations
  @keyframes slideUp {
    0% { 
      opacity:0; 
      transform: translateY(15px)
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% { 
      opacity:0; 
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slideInRight {
    0% { 
      opacity:0; 
      transform: translateX(15px)
    }
    100% {
      opacity: 1;
    }
  }
  .Header {
    padding-top: 7%;
    padding-bottom: 10%;
    .Title {
      font-size: clamp(100%, 48px + 2vw, 72px);
      padding-bottom: 1%;
      color: #222F3E;
      animation: slideUp cubic-bezier(.19,1,.22,1) 1s;
      animation-fill-mode: forwards;
      animation-delay: 0.3s;
      opacity: 0;
    }   
  }
  .SubTitle {
    font-size: clamp( 18px, 2vw, 24px);
    color: #222F3E;
    padding-bottom: 2.5%;
    animation: slideUp cubic-bezier(.19,1,.22,1) 1s;

    animation-fill-mode: forwards;
    animation-delay: 0.4s;
    opacity: 0;
  }
  p {
    font-size: clamp( 18px, 2vw, 24px);
    color: #222F3E;
    padding-bottom: 2.5%;
  }
  .ButtonWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
    animation: slideUp cubic-bezier(.19,1,.22,1) 1s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (max-width: 850px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}


  @media (max-width: 1200px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: 850px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: 650px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  
`

const Button = styled.a`
    background: var(--gray);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    margin-bottom: 64px;
    margin-bottom: clamp( 24px, 2vw, 64px);
    display: flex;
    justify-content: center;

    padding-top: 9px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 12px;

    svg {
      padding: 2px;
    }

    transition: transform 200ms,background 200ms;
    transition-property: transform, background;
    transition-duration: 200ms, 200ms;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;

    :hover {
      transform: translateY(-2px);
      background-color: #75818E;
    }
`;

const ButtonOutline = styled.a`
    background: #fff;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    color: var(--gray);
    border: solid 2px var(--gray);
    text-align: center;
    text-decoration: none;
    margin-bottom: 64px;
    margin-bottom: clamp( 24px, 2vw, 64px);
    display: flex;
    justify-content: center;

    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 12px;

    svg {
      padding: 2px;
    }

    transition: transform 200ms,background 200ms;
    transition-property: transform, background;
    transition-duration: 200ms, 200ms;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;

    :hover {
      transform: translateY(-2px);
    }
`;


// const Section = styled.section`
//   .row {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
//   .column {
//     width: 45%;
//     .text {
//       font-weight: 500;
//       font-size: 24px;
//       color: #222F3E;
//       margin-bottom: 32px;
//     }
//     .lead {
//       font-size: 48px;
//       color: #222F3E;
//       margin-bottom: 32px;
//     }
//   }
// `



const ProjectButton = styled(props => <Link {...props} />)`
  background: var(--gray);
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  width: 175px;
  color: #fff;
  ${'' /* width: 133px; */}
  text-align: center;
  text-decoration: none;
  white-space: nowrap !important ;
  
  display: flex;
  justify-content: center;
  ${'' /* Change to this when you come back to fix
  justify-content: space-around; */}

  padding-top: 2%;
  padding-right: 4%;
  padding-bottom: 2%;
  padding-left: 4%;

  svg {
    padding: 2px;
  }

  @media (max-width: 750px) {
    order: 3;
    width: 100% !important;
    white-space: break-spaces;
  }


  transition: transform 200ms,background 200ms;
  transition-property: transform, background;
  transition-duration: 200ms, 200ms;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  
  :hover {
    transform: translateY(-2px);
    background-color: #75818E;
  }
`;



const Clickable = styled(props => <Link {...props} />)`
  text-decoration: none;
`;



// const SiteFooter = styled.footer`
//   background: #111112;
//   padding: 8% 0;
// `