import * as React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"

const Footer = (props) => {
  return (
    <Grid>

      <nav> 
        <StyledLinkHome to="/">© 2023 Nate Davis <span>- built with gatsby, react & styled components</span></StyledLinkHome>
        <div>
          <a href="https://linkedin.com/in/nate-davis-7936b297" target="_blank" >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#666">
              <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8.951 9.404H6.165V17.5H8.95V9.404zm6.841-.192c-1.324 0-1.993.629-2.385 1.156l-.127.181V9.403h-2.786l.01.484c.006.636.007 1.748.005 2.93l-.015 4.683h2.786v-4.522c0-.242.018-.484.092-.657.202-.483.66-.984 1.43-.984.955 0 1.367.666 1.408 1.662l.003.168V17.5H19v-4.643c0-2.487-1.375-3.645-3.208-3.645zM7.576 5.5C6.623 5.5 6 6.105 6 6.899c0 .73.536 1.325 1.378 1.392l.18.006c.971 0 1.577-.621 1.577-1.398C9.116 6.105 8.53 5.5 7.576 5.5z"/>
            </svg>
          </a>

          <a href="https://github.com/nathanieldavis" target="_blank" className="Github">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="GithubPath" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#666666"/>
              <path d="M15.9999 5.86865C10.4062 5.86865 5.86865 10.4062 5.86865 15.9999C5.86865 20.4062 8.68115 24.1499 12.6062 25.5499C12.6249 25.5561 12.6937 25.5811 12.7124 25.5874C12.7687 25.6061 12.8312 25.6186 12.8937 25.6186C13.2249 25.6186 13.4874 25.3499 13.4874 25.0249C13.4874 25.0062 13.4874 24.9937 13.4874 24.9749C13.4874 24.4374 13.4874 23.7562 13.4874 23.2124C12.8437 23.3437 11.8687 23.4687 11.3374 23.2124C10.6499 22.8812 10.2999 22.4562 9.96865 21.6187C9.55615 20.5999 8.60615 20.3187 8.56865 20.1437C8.53115 19.9624 9.5749 19.6937 10.1124 20.3124C10.6499 20.9312 11.1937 22.1687 12.3499 22.0499C12.9187 21.9937 13.2874 21.8999 13.5187 21.8312C13.5624 21.4312 13.6937 20.9374 14.0249 20.5874C11.3062 20.1374 9.49365 18.6749 9.49365 15.8062C9.49365 14.4999 9.86865 13.4874 10.5312 12.7312C10.4187 12.1062 10.2312 10.6562 10.7312 10.1499C10.7312 10.1499 11.4374 9.7124 13.4624 11.1374C14.2187 10.9624 15.0562 10.8749 15.9499 10.8749C15.9687 10.8749 15.9812 10.8749 15.9999 10.8749C16.0187 10.8749 16.0312 10.8749 16.0499 10.8749C16.9499 10.8812 17.7874 10.9687 18.5374 11.1374C20.5624 9.7124 21.2687 10.1499 21.2687 10.1499C21.7687 10.6624 21.5812 12.1124 21.4687 12.7312C22.1312 13.4937 22.5062 14.5062 22.5062 15.8062C22.5062 18.6749 20.6999 20.1374 17.9749 20.5874C18.4937 21.1312 18.5124 22.0124 18.5124 22.3749C18.5124 22.7187 18.5124 25.0187 18.5124 25.0312C18.5124 25.3624 18.7812 25.6249 19.1062 25.6249C19.1562 25.6249 19.1999 25.6187 19.2499 25.6062C19.2624 25.6062 19.2999 25.5936 19.3124 25.5874C23.2812 24.2124 26.1312 20.4437 26.1312 16.0062C26.1312 10.3999 21.5937 5.86865 15.9999 5.86865Z" fill="white"/>
            </svg>
          </a>
        </div>
      </nav>
    </Grid>
  )
}

export default Footer


const Grid = styled.div`
	border-top: 1px solid rgba(0, 0, 0, 0.1);
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 0%;
    div {
      flex-basis: 1%;
      min-width: 80px;
      svg {
        :hover {
          fill: #808080;
          cursor: pointer;
        }
      }
    }
    .Github {
      float: right;
      svg {
        :hover .GithubPath {
          fill: #808080;
          cursor: pointer;
        }
    }
  }
}
`

const StyledLinkHome = styled(props => <Link {...props} />)`
  color: #222F3E;
  font-size: 24px;
  font-size: clamp( 14px, 2vw, 18px); 
  text-decoration: none;
  font-weight: 500;
  
  span {
    font-size: clamp( 14px, 2vw, 18px); 
    padding-bottom: 0;
    font-style: italic;
    font-weight: 300;
  }
  
  @media (max-width: 600px) {
    span {
      display: none
    }
  }
`;
